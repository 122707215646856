.region-manage-table tr th:nth-child(1) {
  width: 120px;
}

.region-manage-table tr th:nth-child(3) {
  width: 80px;
}

.region-manage-table tr th:nth-child(4) {
  width: 80px;
}

.region_img {
  width: 50px;
  height: 50px;
}

.region_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #191510;
}