* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "inter-bold";
  src: url(../fonts/inter-bold.ttf);
}

@font-face {
  font-family: "inter-medium";
  src: url(../fonts/inter-medium.ttf);
}

@font-face {
  font-family: "inter-regular";
  src: url(../fonts/inter-regular.ttf);
}

@font-face {
  font-family: "inter-semibold";
  src: url(../fonts/inter-semibold.ttf);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.header-main {
  background: #003d90;
  padding: 4px 15px;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.logo-img {
  width: 178px;
  object-fit: cover;
  height: 38px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}

.title-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.search {
  border: 1px solid #5d513e;
  width: 200px !important;
  height: 37px;
  line-height: 31px;
  border-radius: 4px !important;
  margin-right: 10px !important;
  background-color: #ffffffa3 !important;
  display: flex;
}

.search input::placeholder {
  color: #373737;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px !important;
  font-family: "inter-regular";
}

.dashboard-text p,
.title-main .page-title {
  color: #373737;
  font-size: 18px;
  line-height: 20px;
  font-family: "poppins-semibold";
}

.common-button button,
.common-button button:hover {
  font-size: 12px;
  font-family: "inter-semibold";
  padding: 7px 13px 6px 13px;
  line-height: 16px;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
}

.common-button button::after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}

.common-button button:hover:after {
  left: 120%;
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.grey-button button,
.grey-button button:hover {
  background: #fff;
  border: 1px solid #191510;
  color: #191510;
  border-radius: 4px;
}

.blue-button button,
.blue-button button:hover {
  color: #ffffff;
  /* border: 1px solid #0D70D9; */
  margin-left: 10px;
  border-radius: 4px;
  height: 37px;
  border: 0px;
  padding: 8px 16px;
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #fff 0, #fdb931 8%, #9f7928 25%, #8a6e2f 62.5%, #5d4a1f 100%); */
  background-color: #191510;
  font-family: "poppins-regular";
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 12px;
  color: #122b45;
  /* line-height: 15px; */
}

.table-design-main tr th {
  font-family: "inter-semibold";
  padding: 4px 9px !important;
}

.table-design-main tr td {
  font-family: "inter-regular";
  padding: 8px 9px !important;
  border-bottom: 0 !important;
}

.party-management-table tr td:nth-child(2) {
  text-transform: lowercase;
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  /* text-align: center; */

  text-align: left;
}

.input-design-div .password {
  margin-top: 25px !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: #fff;
  border: 1px solid #dfe0e1;
  border-radius: 4px;
  height: 28px;
}

.input-design-div .input-placeholder input::placeholder {
  color: #122b45;
  font-family: "inter-regular";
  /* font-size: 16px; */
  line-height: 20px;
}

.admin-input-design {
  width: 100%;
}

.admin-input-design {
  width: 100%;
}

.input-design-div.with-border input {
  /* height: 28px; */
  height: 35px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 15px;
}

.input-design-div.with-border input,
.set-textarea-box-top .MuiInputBase-formControl {
  border: 1px solid #ccc;
  /* border-radius: 8px;  */
  border-radius: 4px;
  background-color: #fff;
  font-family: "inter-regular";
  font-size: 14px;
}

.set-textarea-box-top fieldset {
  border: none !important;
}

.with-border .MuiFilledInput-underline {
  /* height: 28px !important; */
  height: 35px !important;

  font-size: 14px !important;
  letter-spacing: 0;
}

.input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold";
  padding-bottom: 8px !important;
  display: flex;
}

.line {
  height: 1px;
  background: #ebebeb;
}

.save-btn button,
.save-btn button:hover,
.save-btn button:focus {
  min-width: 110px;
}

.table-container {
  box-shadow: none !important;
  border-radius: 12px !important;
  background-color: transparent !important;
}

.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}

.set-date-picker .MuiInputBase-colorPrimary {
  background: #fff;
  border: 1px solid #00000033 !important;
  border-radius: 4px;
  width: 100%;
  /* height: 28px; */
  /* border-radius: 10px; */
  height: 35px;
  border-radius: 5px;
}

.set-date-picker fieldset {
  border-color: transparent !important;
}

.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;
  font-family: "inter-regular" !important;
  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #373737;
  padding: 0 10px;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  font-family: "inter-medium";
  color: #191510;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #191510;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 1;
  background-color: #51493c38;
  font-family: "poppins-semibold";
}

.pagination-design {
  margin-top: 15px;
}

.filter-btn button,
.filter-btn button:hover {
  max-width: 34px;
  height: 34px;
  min-width: 34px;
  background: #003d90;
  border: 1px solid #003d90;
}

.gallery-image-management .MuiTableCell-root .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}

.user-reviews .MuiTableCell-root .MuiFormControlLabel-root {
  margin-right: 0;
}

/* 10-5 css */

.main-login {
  background-image: url("../images/Jpg/backlogin.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.rightbox {
  background: #042443c4;
  height: 100vh;
  margin-left: auto;
  max-width: 50%;
  border-radius: 0px 0px 0px 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-login-main {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
}

.logo-set {
  padding: 30px 0px 0px 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-set img {
  height: 81px;
  width: 383px;
  object-fit: contain;
}

.logo-set2 img {
  height: 63px;
  width: 300px;
  object-fit: contain;
}

.box-text p {
  color: white;
  font-size: 30px;
  line-height: 36px;
  font-family: "inter-semibold";
}

.box-login-text p {
  font-family: "inter-regular";
  font-size: 16px;
  color: white;
  line-height: 20px;
  margin: 15px 0px 40px 0px;
}

.main-box {
  max-width: 300px !important;
  width: 300px;
  flex: 0 0 300px;
}

.login-btn-main {
  margin: 30px 0px 20px;
}

.login-btn button {
  width: 100%;
  font-family: "inter-semibold";
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #fff 0,
      #fdb931 8%,
      #9f7928 25%,
      #8a6e2f 62.5%,
      #5d4a1f 100%
    );
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: none;
  min-height: 36px;
  font-family: "inter-regular";
  text-transform: capitalize;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  min-width: 300px;
}

.forgot p {
  margin: 0px !important;
  text-align: right !important;
}

.input-design-div .MuiInputBase-input {
  /* padding: 10px 16px !important; */
  padding: 3px 10px !important;
  color: #122b45;
  background: #fff !important;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 30px !important;
  -webkit-background-clip: text !important;
}

.dashboard-text p {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  display: flex;
}

.main-box-dash {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 18.81%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  /* border: 1px solid #DFE0E1; */
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "inter-medium";
  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.forgot a {
  text-decoration: none;
}

.barge-list-main-box {
  border-radius: 8px;
  /* / max-width: 323px; / */
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
  background: #f0f3f8;
}

.barge-set-dash-main {
  display: flex;

  /* / width: calc(50% + 15px); / */
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-medium";
  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-regular";
  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-regular";
  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-medium";
  color: #122b45;
}

.pt-5 {
  padding-top: 5px !important;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #5e544433;
  border-radius: 10px;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.admin-input-design {
  width: 100%;
}

.profile-set {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #122b45;
  font-size: 14px;
  line-height: 17px;
  font-family: "inter-regular";
  min-height: unset;
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

li.logout-profile {
  color: #d32f2f !important;
  font-size: 14px;
  line-height: 17px;
  min-height: unset;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

/* / start admin header css / */
.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-header-right button {
  padding: 0;
  min-width: unset;
}

/* / end admin header css / */

/* 10-5 css end */
.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-header-inner .filter-title {
  color: #003d90;
  font-family: "inter-bold";
  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: transparent;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 120px);
  /* margin-top: 50px; */
}

.card-main {
  background: #fff;
  padding: 30px;
  box-shadow: 0px 3px 30px #0000000d;
  border-radius: 10px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 15px;
}

.profile-card-main {
  max-width: 710px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  /* margin: auto; */
  margin-bottom: 15px;
}

.change-input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-medium";
  padding-bottom: 6px !important;
  display: flex;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 1px;
  background: red;
  height: 28px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  max-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.supply-table tr th:nth-child(1),
.supply-table tr td:nth-child(1) {
  width: 6%;
}

.supply-table tr th:nth-child(2),
.supply-table tr td:nth-child(2) {
  width: 10%;
}

.supply-table tr th:nth-child(3),
.supply-table tr td:nth-child(3) {
  width: 10%;
}

.supply-table tr th:nth-child(4),
.supply-table tr td:nth-child(4) {
  width: 5%;
}

.supply-table tr th:nth-child(5),
.supply-table tr td:nth-child(5) {
  width: 11%;
}

.supply-table tr th:nth-child(6),
.supply-table tr td:nth-child(6) {
  width: 6%;
}

.supply-table tr th:nth-child(7),
.supply-table tr td:nth-child(7) {
  width: 8%;
}

.supply-table tr th:nth-child(8),
.supply-table tr td:nth-child(8) {
  width: 5%;
}

.supply-table tr th:nth-child(9),
.supply-table tr td:nth-child(9) {
  width: 5%;
}

.supply-table tr th:nth-child(10),
.supply-table tr td:nth-child(10) {
  width: 4%;
}

.supply-table tr th:nth-child(11),
.supply-table tr td:nth-child(11) {
  width: 7%;
}

.supply-table tr th:nth-child(12),
.supply-table tr td:nth-child(12) {
  width: 10%;
}

.supply-table tr th:nth-child(13),
.supply-table tr td:nth-child(13) {
  width: 18%;
}

.supply-table tr th:nth-child(14),
.supply-table tr td:nth-child(14) {
  width: 10%;
}

.tanker-table tr th:nth-child(1),
.tanker-table tr td:nth-child(1) {
  width: 12%;
}

.tanker-table tr th:nth-child(2),
.tanker-table tr td:nth-child(2) {
  width: 20%;
}

.tanker-table tr th:nth-child(3),
.tanker-table tr td:nth-child(3) {
  width: 12%;
}

.tanker-table tr th:nth-child(4),
.tanker-table tr td:nth-child(4) {
  width: 14%;
}

.tanker-table tr th:nth-child(5),
.tanker-table tr td:nth-child(5) {
  width: 18%;
}

.tanker-table tr th:nth-child(6),
.tanker-table tr td:nth-child(6) {
  width: 17%;
}

.tanker-table tr th:nth-child(7),
.tanker-table tr td:nth-child(7) {
  width: 20%;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.margin-left-5 {
  margin-left: 5px !important;
}

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 18px;
  line-height: 24px;
  font-family: "inter-medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal.MuiModal-root {
  margin: 15px;
}

.modal .modal-inner-main {
  max-width: 600px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 24px 24px 0;
}

.modal-header .modal-close-icon {
  filter: invert(1);
  cursor: pointer;
}

.modal-body {
  padding: 0 24px 24px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

/* end add user modal css */
/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "inter-medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 10px;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background: #eaac35;
  border-radius: 10px;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

.incre-btn-main .inc-dic-btn,
.incre-btn-main .inc-dic-btn:hover {
  background-color: #003d90;
  height: 34px;
  min-width: 34px;
  color: #fff;
  line-height: 23px;
  font-size: 17px;
  margin-top: 15px;
  border-radius: 5px;
  padding-bottom: 8px;
}

.incre-btn-main .inc-dic-btn:first-child {
  margin-right: 15px;
}

.incre-btn-main .Mui-disabled {
  color: #c5c1c1 !important;
}

.Mui-error {
  margin-left: 0px !important;
}

.action-menu-icon {
  font-size: 20px !important;
}

.action-text {
  font-family: "inter-regular" !important;
  color: #122b45 !important;
  font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 23-5 */
.filter-datepicker .MuiOutlinedInput-input {
  border: 0 !important;
}

.filter-date-overflow > .MuiStack-root {
  overflow: hidden;
}

.pdf-design.MuiBox-root.css-0 {
  font-family: "inter-medium";
  font-size: 14px;
  background: #003d90;
  color: #d8d8dd;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}

.pdf-upload-design {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.filter-close-btn img {
  height: 23px;
  width: 23px;
}

.modal-close-button {
  height: 23px;
  width: 23px;
}

/* 9-6 */
/* / select compny start / */
.select-compny-cad-main {
  max-width: 1118px !important;
  width: 100%;
}

.select-card-main {
  background: #fff;
  padding: 50px;
  box-shadow: 0px 3px 30px #0000000d;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.title-main .select-page-title {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-family: "inter-bold";
  text-align: center;
}

.compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 20.5px;
  background: white;
  cursor: pointer;
}

.compny-img-inner {
  width: 196px;
  height: 158px;
  border-radius: 10px;
}

.set-select-img {
  text-align: center;
}

.select-compny-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-semibold";
  color: #122b45;
}

.select-text-set {
  margin-top: 20px;
}

.select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.select-img-main {
  margin: 9px;
  width: 241px;
}

.mb-41 {
  margin-bottom: 41px;
}

.select-check-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.select-check-box svg {
  width: 30px;
  height: 30px;
}

.select-check-box .MuiCheckbox-colorPrimary {
  padding-top: 16px 0px 0px !important;
}

.select-modal-box {
  max-width: 551px !important;
  width: 100% !important;
  border: 1px solid #707070 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
}

.select-modal-header {
  background: #003d90;
  padding: 15px 20px;

  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-modal-header-text {
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-family: "inter-semibold";
}

.select-modal-img-box {
  padding: 40px 50px;
}

.select-text-set-modal {
  margin-top: 9px;
}

.modal-compny-img-inner {
  width: 94px;
  height: 101px;
  border-radius: 10px;
}

.modal-compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 14px;
  background: white;
}

.modal-select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select-img-main-modal {
  margin: 14px;
  width: 130px;
}

.modal-set-select-img {
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.modal-select-compny-text {
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-family: "inter-semibold";
  color: #122b45;
}

.set-modal-close-icon {
  height: 30px;
  width: 30px;
}

.modal-close-img {
  height: 16px;
  width: 16px;
}

.modal-close-icon-small {
  height: 20px;
  width: 20px;
}

.modal-icon-btn {
  margin-top: 11px !important;
}

.res-btn-close {
  min-width: auto !important;
  padding: 0px !important;
}

.modal-compny-img .active {
  border: 1px solid #1f65db;
}

.select-img-main-modal.active .modal-compny-img {
  border: 2px solid #1f65db;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-grey {
  display: none;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-blue {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-grey {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-blue {
  display: none;
}

.select-img-main.active .compny-img {
  border: 2px solid #1f65db;
}

.select-img-main.active .select-check-box .modal-close-icon-small-blue {
  display: block;
  margin: auto;
}

.select-img-main.active .select-check-box .modal-close-icon-small-grey {
  display: none;
}

.select-img-main .select-check-box .modal-close-icon-small-grey {
  display: block;
  margin: auto;
}

.select-img-main .select-check-box .modal-close-icon-small-blue {
  display: none;
}

/* / select compny end / */
.barge-dashboard-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe0e1;
  border-radius: 10px;
}

.barge-common-box {
  background-color: #5e544433;
  padding: 20px;
  border-radius: 12px;
}

.one-line-table {
  /* padding: 0 15px; */
}

.table-design-main {
  /* background-color: #f0f3f8; */
}

/* .page-content-box {
  margin-top: 30px;
  margin-bottom: 30px;
} */
.dashboard-containt-main {
  padding: 60px 30px 0px;
}

.filter-date-overflow .MuiStack-root {
  width: 100%;
}

.barge-dashboard-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-line-table tr th,
.one-line-table tr td {
  white-space: nowrap;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  margin: 0 0 0 10px;
  padding: 0;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium svg {
  font-size: 20px;
}

.input-design-div .MuiInputBase-fullWidth {
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 35px;
  line-height: 25px;
}

.input-design-div .set-date-picker .MuiInputBase-input {
  background: transparent;
}

/* .table-design-main tr th:last-child, .table-design-main tr td:last-child {
  width: 50px;
} */
.white-login-box.MuiBox-root.css-0 {
  background: #000000b0;
  padding: 50px;
  border-radius: 12px;
}

.bluebox-text p {
  color: #ffffff;
}

.login-input-design-div .MuiFilledInput-underline,
.login-input-design-div .MuiFilledInput-underline:hover,
.login-input-design-div .MuiFilledInput-underline.Mui-focused {
  height: 36px;
  border: 1px solid #d4d9e1;
}

.logo-set2 {
  margin-bottom: 10px;
}

/* / 14/6/23 start css / */

.profile-image-center {
  text-align: center;
}

.profile_img_wrap {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 1rem;
}

.profile_img_wrap img {
  max-width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.input-design-div-set-back .MuiInputBase-input {
  font-size: 14px !important;
  background-color: transparent !important;
}

.change-password .MuiInputBase-root,
.change-password .MuiFilledInput-underline:hover,
.change-password .MuiFilledInput-underline.Mui-focused {
  height: 35px;
}

/* .change-password .admin-input-design .MuiInputAdornment-filled button svg {
  width: 18px;
  height: 18px;
} */

.change-password-text {
  margin-bottom: 20px;
}

.input-design-div .password-set {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.input-design-div .password-set-box {
  margin-top: 0px !important;
}

.input-design-div .label-set {
  margin-top: 0px !important;
}

.input-label-new {
  background: #f0f3f8 !important;
}

/* / 14/6/23 start css / */

body .MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 4px;
  margin-top: 5px;
  border: 1px solid #e1dddd;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/* table start */
.table-design-main tr th {
  background: #51493c38;
  padding: 20px 9px !important;
  white-space: nowrap;
  color: #373737;
  font-family: "poppins-semibold";
  border: 0;
}

.dashboard-containt-main {
  padding: 60px 30px 0px;
  background: #f0f3f8 !important;
}

.table-design-main {
  /* background: #191510; */
  border-collapse: separate !important;
  border-spacing: 0px 9px !important;
  border-radius: 0 !important;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  padding-left: 20px !important;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  padding-right: 20px !important;
}

.barge-common-box-table {
  background-color: #f0f3f8;
  padding: 0px !important;

  border-radius: 10px;
}

.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-design-main tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-design-main tr td {
  background: #fff;
  display: table-cell;
  font-family: "poppins-regular";
  color: #282828;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.profile-inner .dashboard-item {
  background: transparent;
}

.set-textarea-box {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-profile-set {
  font-size: 14px;
  font-family: "inter-bold";
  line-height: 20px;
  color: #122b45;
  padding: 0px 8px;
  text-transform: capitalize;
  display: flex;
}

.set-admin-header-profile-icon {
  width: 33px !important;
  height: 33px !important;
}

.set-profile-inner {
  font-size: 8px;
  line-height: 10px;
  color: #959595;
  padding: 0px 8px;
  font-family: "inter-regular";
}

.new-menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 200px;
}

.pink-btn-menu {
  background: #ffb8d0 !important;
  text-align: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin: 0px 16px !important;
}

.drop-header-btn-set {
  padding: 0px 0px 6px !important;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 16px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;

  width: 200px;
}

.css-2hzjrb-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.input-design-div fieldset {
  border-color: #ccc;
}

.invoice-table tr td {
  white-space: nowrap;
}

.paiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  margin-right: 10px;
}

.unpaiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected {
  background: #5e544433;
}

.input-design-div .MuiOutlinedInput-root:hover fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.MuiOutlinedInput-root .MuiSelect-select .multiselect-item {
  display: inline-flex;
  background: #c9e0ff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 2;
  font-family: "poppins-semibold";
  color: #003d90;
}

.total-service {
  display: flex;
  justify-content: space-between;
}

.service-right .payment-row {
  display: flex;
}

.service-right .payment-row .amount-title {
  width: 180px;
  font-family: "inter-medium";
  text-transform: capitalize;
  font-size: 14px;
  line-height: 25px;
}

.service-right .payment-row .amount-number {
  font-family: "poppins-regular";
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  width: 140px;
}

.service-total-div {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.addinvoice-table tr th:first-child,
.addinvoice-table tr td:first-child {
  min-width: 143px;
  max-width: 143px;
}

.addinvoice-table tr th:nth-child(2),
.addinvoice-table tr td:nth-child(2) {
  /* max-width: 200px !important;
  min-width: 200px !important; */
  max-width: 270px !important;
  min-width: 270px !important;
}

.addinvoice-table tr th:nth-child(3),
.addinvoice-table tr td:nth-child(3) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.addinvoice-table tr th:nth-child(4),
.addinvoice-table tr td:nth-child(4) {
  min-width: 150px !important;
}

.addinvoice-table tr th:nth-child(5),
.addinvoice-table tr td:nth-child(5) {
  min-width: 140 !important;
}

.addinvoice-table tr th:last-child,
.addinvoice-table tr td:last-child {
  /* max-width: 310px !important;
  min-width: 310px !important; */
  max-width: 240px !important;
  min-width: 240px !important;
}

.signleinvoices-view tr th:last-child,
.signleinvoices-view tr td:last-child {
  max-width: 281px !important;
  min-width: 281px !important;
}

.addinvoice-table tr td {
  vertical-align: top;
}

.addinvoice-table tr td {
  padding: 11px 9px !important;
}

.service-main-total .amount-title {
  font-family: "inter-semibold" !important;
}

.service-main-total .amount-number {
  /* font-family: 'inter-semibold' !important; */
  font-family: "poppins-semibold" !important;
}

.invoice_number {
  display: flex;
  justify-content: space-between;
}

.invoice_number_align {
  margin-left: 10px !important;
  font-size: 16px !important;
}

.party_btn {
  display: flex !important;
  justify-content: start;
  /* margin-right: "-20px"; */
  /* margin-left: 0px !important; */
}

.party_submit_btn {
  margin-left: 0px !important;
}

.input-textarea textarea {
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  border-radius: 4px;
  background-color: #fff;
  font-family: "inter-regular";
  font-size: 14px;
  padding: 10px 10px !important;
  outline: none;
  resize: none;
}

.ml-0 {
  margin-left: 0 !important;
}

/* 13/7/2023 start */

.set-list-button {
  min-width: auto !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.set-list-button svg {
  width: 12px !important;
  height: 12px !important;
}

.grey-button-list button {
  margin-right: 10px;
}

.invoice-table-set tr th:first-child {
  padding-left: 40px !important;
}

.tree-analysis-main .tree-list-analysis {
  padding: 0px;
}

.tree-analysis-main .tree-list-analysis .tree-listitem-analysis {
  padding: 0px;
}

.tree-analysis-main .tree-list-analysis {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
}

.tree-analysis-main .tree-list-analysis .tree-listitem-analysis {
  padding: 0px 5px;
  width: calc(150% / 3);
  margin-bottom: 10px;
}

.tree-analysis-main
  .tree-list-analysis
  .tree-listitem-analysis
  .box-card-tree-analysis {
  background: white;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.box-card-tree-analysis .title-tree-list {
  font-family: "poppins-semibold";
  /* font-size: 17px; */
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 5px;
  color: #003d90;
}

.box-card-tree-analysis .price-tree-list {
  font-family: "poppins-regular";
  font-size: 13px;
  margin-bottom: 0px;
  color: #000;
}

.tree-analysis-main {
  height: 201px;
  padding-right: 10px;
  margin-right: -9px;
  overflow: auto;
}

/* width */
.tree-analysis-main::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

/* Track */
.tree-analysis-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 2px;
}

/* Handle */
.tree-analysis-main::-webkit-scrollbar-thumb {
  background: #7a808b;
  border-radius: 2px;
}

/* Handle on hover */
.tree-analysis-main::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
  border-radius: 10px;
}

.edit-btn-bank {
  display: flex;
  background: #e1e1e1;
  padding: 3px;
  border-radius: 5px;
  margin-right: 9px;
}

.edit-btn-bank svg {
  fill: transparent;
  stroke: black;
  stroke-width: 2px;
}

.edit-flex-btn {
  display: flex;
  align-items: center;
}

.flex-edittext-color {
  color: #000;
}

.set-list-button:hover {
  background-color: transparent;
}

.invoice-view-cursor {
  cursor: pointer !important;
}

.invoice-amount {
  font-size: 12px !important;
}

.barge-search-main .search {
  margin-left: auto;
  margin-right: 0px;
}

.card-flex-invoice {
  width: 100%;
}

.card-flex-invoice .card-graph-content-inner {
  margin-right: 0px;
  display: flex;
  align-items: center;
}

.card-graph-content-inner .invoice-col-1 {
  max-width: 120px;
  flex: 0 0 120px;
  width: 100%;
  margin-right: 15px;
}

.card-graph-content-inner .invoice-col-2 {
  max-width: 30px;
  flex: 0 0 30px;
  width: 100%;
  margin-right: 15px;
}

.card-graph-content-inner .invoice-col-3 {
  flex: 1;
}

.bank-quanty-flex {
  display: flex;
  align-items: center;
}

.bank-quanty-flex {
  max-width: 120px;
  width: 100%;
  flex: 0 0 120px;
  margin-right: 20px;
}

.banker-detail-unit {
  flex: 1;
}

.pagination-count {
  display: flex;
}

.banker-flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-label-invoice {
  color: #122b45;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-semibold";
  padding-bottom: 8px !important;
  display: flex;
}

.input-design-div .invoice-select-drop .MuiInputBase-root,
.input-design-div .invoice-particulars-select-drop .MuiInputBase-root {
  padding: 0px !important;
}

.input-design-div .invoice-particulars-select-drop .MuiInputBase-input {
  padding-right: 50px !important;
}

.model-close-icon {
  display: flex !important;
  justify-content: end !important;
  margin-left: auto;
}

.activity-log-header {
  /* border: 5px solid red !important; */
  border-bottom: 5px solid #a6ccff !important;

  /* border-bottom: 5px solid #003d90 !important; */
  border-radius: 2px !important;
  width: fit-content !important;
}

.empty-record-align {
  text-align: center !important;
}

/* bug issue fixed css */

.rate-table-design tr th:nth-child(1),
.rate-table-design tr td:nth-child(1) {
  min-width: 70%;
  max-width: 70%;
  width: 70%;
}

.rate-table-design tr th:nth-child(2),
.rate-table-design tr td:nth-child(2) {
  min-width: 15%;
  width: 15%;
  max-width: 15%;
}

.rate-table-design tr th:nth-child(3),
.rate-table-design tr td:nth-child(3) {
  min-width: 15%;
  width: 15%;
  max-width: 15%;
}

/* .res-button-width .view-invoice-btn{
   margin: 0px 7px;
} */

.res-button-width .view-invoice-btn:first-child {
  margin-left: 0px;
}

.res-button-width .view-invoice-btn:last-child {
  margin-right: 0px;
}

.flex-column-res {
  margin-bottom: 15px;
}

.tab-comman-padding {
  padding: 24px 0px;
}

.inquiry-submit-btn {
  margin-top: 30px !important;
}

.remark-text {
  text-transform: none !important;
}

.remove-white-space-select-plceholder {
  padding: 0 px !important;
}

.order-active-log-header {
  margin-top: 15px !important;
}

.challan-status {
  text-transform: none !important;
}

.status-success {
  color: #008000 !important;
}

.status-error {
  color: #ff0000 !important;
}

.inquiry-list {
  cursor: pointer;
}

.delete-modal-btn-flex .modal-inquiry-success-btn {
  background: #fff;
  border: 1px solid #eaac35;
  color: #008000;
  border-radius: 10px;
  margin-right: 20px;
}

.form-lable,
.MuiInputBase-input {
  font-family: "inter-regular" !important;
}

.error-text {
  color: #d32f2f !important;
}

.table-design-main tbody tr td.no-data-in-list {
  border-radius: 10px !important;
}

.form-text-area {
  height: 85px;
  overflow: hidden;
  width: 100%;
  font-family: inter-regular;
  padding: 4.5px 14px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  resize: vertical;
}

.form-text-area:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0;
}

.MuiButtonBase-root.MuiCheckbox-root svg {
  font-size: 18px;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #191510;
}

.MuiTableCell-root .MuiFormControlLabel-root .MuiSwitch-root {
  margin-left: 11px;
}

.user-save-icon {
  margin-right: 5px;
}

.form-group .MuiSelect-select {
  padding: 4.5px 14px;
  background-color: #ffffffa3;
  border: 1px solid #5d513e;
}

.form-group .Mui-focused fieldset {
  border-color: rgba(0, 0, 0, 0.87) !important;
  border-width: 1px !important;
}

.form-group .MuiAutocomplete-inputRoot {
  padding: 5.5px 14px !important;
}

.form-group .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.date-picker .form-control {
  width: 100%;
}

/* start file upload img css */
.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.file-upload-btn-main.multiple-images {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  min-width: 150px;
  padding: 0px;
  background-color: #5e544433;
  height: 100px;
  width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  box-shadow: none;
  overflow: hidden;
}

.file-upload-btn-main .file-upload-btn:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.upload-profile-img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.file-upload-btn-close {
  position: relative;
}

.file-upload-btn-close:hover::before,
.file-upload-btn-close:hover::after{
  content: "";
}

.file-upload-btn-close::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.file-upload-btn-close::after {
  position: absolute;
  background: url(../images/Svg/img-upload-icon.svg);
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(45deg);
}

.upload-img {
  width: 30px;
  height: 30px;
}

/* end file upload img css */

.dashboard-body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.dashboard-body .dashboard-box {
  background: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  text-decoration: none;
}

.dashboard-body .dashboard-box .dashboard-box-left .dashboard-box-title {
  color: #9a9a9a;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;
}

.dashboard-body .dashboard-box .dashboard-box-left .dashboard-box-value {
  font-family: "poppins-semibold";
  color: #373737;
  font-size: 13px;
  line-height: 17px;
}

.dashboard-body .dashboard-box .dashboard-box-right {
  display: flex;
}

.dashboard-body .dashboard-box .dashboard-box-right .dash-icons {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
