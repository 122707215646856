@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .card-graph-content-inner .card-total {
    font-size: 10px;
    line-height: 13px;
    max-width: 100px;
  }

  .card-graph-content-inner .card-price {
    font-size: 11px !important;
    line-height: 22px;
  }
}

@media screen and (max-width: 1199px) {
  .dashboard-body {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {}

/* phone and tablets */
@media screen and (max-width: 991px) {
  .pl-none {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .main-header {
    padding-right: 15px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #0000006b;
    bottom: 0;
    z-index: 9999;
  }

  .sidebar-none {
    left: 0px;
  }

  .res-menu {
    display: block;
  }

  .rightbox {
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }

  .logo-set img {
    width: 300px;
  }

  .background-hight {
    height: 100%;
  }

  .set-filter-main {
    margin-top: 15px;
  }

  /* / start raponsive 13/6 / */
  .select-modal-box {
    max-width: 95%;
  }

  /* / end raponsive 13/6 / */

  .background-hight {
    height: 100%;
  }

  .set-filter-main {
    margin-top: 15px;
  }

  .chart-box {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .mini-card {
    margin-bottom: 15px;
  }

  .chart-box:last-child {
    margin-bottom: 0;
  }

  .sidebar-main.active {
    left: -230px;
  }

  .main-header .collapse-btn::after {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, .5);
    content: "";
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    transition: all .3s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 3;
  }

  .main-header.side-bar .collapse-btn::after {
    opacity: 1;
    overflow: auto;
    visibility: visible;
  }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .cattable-devider table thead tr th:first-child {
    width: 3%;
  }

  .cattable-devider table thead tr th:nth-child(2) {
    width: 25%;
  }

  .cattable-devider table thead tr th:last-child {
    width: 6%;
  }

  .cattable-port table thead tr th:last-child {
    width: 6%;
  }

  .cattable-devider table tbody tr td:nth-child(2) {
    width: 36%;
  }

  .cattable-devider table tbody tr td:first-child {
    width: 4%;
  }

  .mini-card {
    min-width: 228px;
  }

  .mini-card:nth-child(3n-3) {
    margin-right: 0;
  }

  .main-header {
    padding-left: 15px;
  }

  .admin-panel-content {
    padding: 20px 15px;
  }

  .main-box-dash {
    max-width: 100%;
    width: calc(100% / 2 - 15px);
  }

  .supply-table {
    min-width: 1329px;
  }

  .tanker-table {
    min-width: 687px;
  }

  .invoice-table {
    min-width: 1380px;
  }

  .res-menu-icon svg {
    display: block;
  }

  .res-close-btn {
    display: flex !important;
    margin-left: auto !important;
    margin-top: 20px !important;
    width: 20px;
    height: 20px;
    padding: 0 !important;
  }

  .res-close-btn img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  .header-ul-main {
    display: none;
    display: block;
    background: #003d90;
    position: fixed;
    top: 0;
    width: 250px;
    left: 0;
    bottom: 0;
    z-index: 99;
    transition: 0.5s all;
  }

  .header-ul-main .header-ul {
    display: block;
    padding-top: 50px;
  }

  .header-ul li {
    margin-bottom: 10px;
    padding: 7px 15px;
  }

  /* / start raponsive 13/6 / */
  .select-img-box {
    justify-content: center;
  }

  .filter-close-btn img {
    height: 28px;
    width: 23px;
  }

  /* / end raponsive 13/6 / */

  /* .chart-box {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }

    .mini-card {
        min-width: 232px;
        margin-bottom: 15px;
    }
    .chart-box:last-child {
        margin-bottom: 0;
    }
    .cattable-devider table thead tr th:last-child {
        width: 9%;
    }
    .cattable-port table thead tr th:last-child{
        width: 7%;
    }
    .cattable-port table tbody tr td:last-child {
        width: 9%;
    } */
}

/* phone */
@media (max-width: 767px) {
  .main-header {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .modal-header {
    padding: 12px 12px 0;
  }

  .modal-body {
    padding: 0 12px 12px;
  }

  .margin-search-comman-main {
    margin-top: 10px;
  }

  .single-invoice-taxable {
    margin-bottom: 20px;
  }

  .flex-column-res,
  .res-total-view-invoice {
    flex-direction: column;
  }

  .res-button-width .view-invoice-btn {
    min-width: 51px;
  }

  .res-button-width {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .choose-your-company-screen {
    padding: 30px;
  }

  .chart-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .res-barge-table {
    min-width: 716px;
  }

  .cattable-devider table thead tr th:last-child {
    width: 15%;
  }

  .cattable-devider table thead tr th:nth-child(2) {
    width: 23%;
  }

  .cattable-devider table thead tr th:first-child {
    width: 7%;
  }

  .cattable-port table thead tr th:last-child {
    width: 18%;
  }

  .cattable-port table thead tr th:nth-child(2) {
    width: 31%;
  }

  .cattable-port table thead tr th:first-child {
    width: 10%;
  }

  .header-ul-main {
    display: none;
    display: block;
    background: #003d90;
    position: fixed;
    top: 0;
    width: 200px;
    left: 0;
    bottom: 0;
    z-index: 99;
    transition: 0.5s all;
  }

  .header-ul-main .header-ul {
    display: block;
    padding-top: 50px;
  }

  .header-ul li {
    margin-bottom: 10px;
    padding: 7px 15px;
  }

  .main-box-dash {
    max-width: 100%;
    width: calc(100% / 1);
  }

  .profile-card-main,
  .change-password-main {
    max-width: 340px;
    padding: 15px;
  }

  .res-title-header-flex {
    flex-direction: column;
  }

  .res-set-search {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .res-blue-button button,
  .res-blue-button button:hover {
    margin: 10px 10px 0 0 !important;
  }

  .supply-table {
    min-width: 1329px;
  }

  .filter-main .MuiPaper-elevation {
    min-width: 300px;
  }

  .tanker-table {
    min-width: 687px;
  }

  .invoice-table {
    min-width: 1380px;
  }

  .res-menu-icon svg {
    display: block;
  }

  .res-close-btn {
    display: flex !important;
    margin-left: auto !important;
    margin-top: 20px !important;
    width: 20px;
    height: 20px;
    padding: 0 !important;
  }

  .res-close-btn img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  /* / start raponsive 13/6 / */
  .select-modal-box {
    max-width: 95% !important;
  }

  .modal-select-img-box {
    justify-content: center;
  }

  .select-modal-img-box {
    padding: 25px 25px;
  }

  .res-profile-content {
    min-height: calc(100vh - 83px) !important;
    margin-top: 83px !important;
  }

  .select-card-main {
    padding: 30px;
  }

  .dashboard-text p,
  .title-main .select-page-title {
    font-size: 20px;
    line-height: 27px;
  }

  .mb-41 {
    margin-bottom: 20px;
  }

  .select-img-box {
    justify-content: center;
  }

  .compny-img {
    padding: 18.5px;
  }

  .compny-img-inner {
    width: 158px;
    height: 128px;
  }

  /* / end raponsive 13/6 / */

  .mini-card {
    width: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
  }

  .mini-card-img {
    background-color: #fccaf2;
    border-radius: 4px;
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  .card-right-content .card-total {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 0;
  }

  .card-right-content .card-price {
    font-size: 14px;
    line-height: 20px;
  }

  .mini-card-img img {
    width: 16px;
    height: 16px;
  }

  .mini-card:nth-child(2n-2) {
    margin-right: 0;
  }

  .chart-header .chart-title {
    font-size: 13px;
    line-height: 19px;
  }

  .main-header {
    padding-left: 15px;
  }

  .header-user-detail {
    display: none;
  }

  .admin-panel-content {
    padding: 20px 15px;
  }

  .filter-close-btn img {
    height: 28px;
    width: 23px;
  }
}

@media (max-width: 599px) {
  .dashboard-body {
    grid-template-columns: repeat(1, 1fr);
  }

  .res-set-search {
    gap: 15px;
  }
}