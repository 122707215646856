.container {
  width: 100%;
  background-color: #f0f3f8;
  padding: 30px;
  /* border: 1px solid #DFE0E1; */
  border-radius: 10px;
}
.user-heading {
  margin-left: 18px;
  font-size: 20px;
}
.user-management-inputfield {
  height: 15px !important;
}
.pagination {
  margin-top: 7px;
  margin-bottom: 7px;
}
