.cinema_img {
  width: 50px;
  height: 50px;
}
.cinema_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #191510;
}
